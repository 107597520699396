<template>
  <PageHeaderLayout>
    <div class="main-page-content">
      <el-row :gutter="30">
        <el-col :span="4" class="hidden-sm-and-down">
          <div class="menuBox">
            <div v-for="(item,index) in menuData" :key="item.title" class="menu" :class="index===0?'active':''">
              <div>
                <svg-icon class="svgCol" :fill="'white'" :icon-class="item['icon']"></svg-icon>
                {{ item.title }}
                <span>{{ pagingData.total }}</span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :md="20" :sm="24">
          <div class="noticeList">
            <el-radio-group v-model="stateTabs" @change="handleTabs" size="small">
              <el-radio-button label="all">全部</el-radio-button>
              <el-radio-button label="waiting">未处理</el-radio-button>
              <el-radio-button label="processed">已处理</el-radio-button>
            </el-radio-group>
            <el-date-picker
                @change="handleTimePicker"
                style="margin-left: 100px;width: 600px"
                size="small"
                v-model="searchCondition.date_range"
                type="daterange"
                align="right"
                value-format="yyyy-MM-dd"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions">
            </el-date-picker>
          </div>
          <div class="noticeBox">
            <el-collapse v-loading="noticeLoading" v-model="activeNames"
                         v-if="applyList.length>0">
              <el-collapse-item :name="item.id"
                                class="noticeItem"
                                v-for="item in applyList"
                                :key="item.id">
                <template slot="title">
                  <el-badge is-dot v-if="item.status===1" style="top: 4px;margin-right: 10px"></el-badge>
                  <b> {{ item.apply_user_name }}的角色申请</b>
                  <span class="crTime"> {{ item.created_at }}  </span>
                </template>
                <div class="contentBox">
                  <el-image :src="computedImg(item.status)" class="statusImg"></el-image>
                  <div class="text">
                    申请人：{{ item.apply_user_name }} <br/>
                    申请角色：{{ item.role_names }}
                    <span v-if="item.status===3" style="color: red;margin-left: 20px">
                      拒绝理由：{{  item.rej_reason }}</span><br/>
                    备注：{{ item.remark }}

                  </div>
                  <div class="buttons" v-if="item.status===1">
                    <el-button @click="handlePass(item)" type="success" style="margin-bottom:4px">通&nbsp;&nbsp;&nbsp;过
                    </el-button>
                    <br/>
                    <el-button @click='handleRej(item)' type="danger">拒&nbsp;&nbsp;&nbsp;绝</el-button>
                  </div>
                </div>
              </el-collapse-item>
            </el-collapse>
            <span v-else-if="!noticeLoading">
              暂无信息
            </span>
          </div>
          <el-row>
            <el-col :span="24" style="text-align: right">
              <Pagination :limit.sync="pagingData.page_size" :page.sync="pagingData.current_page"
                          :total="pagingData.total"
                          @pagination="getFoYouApply"/>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </PageHeaderLayout>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import 'element-ui/lib/theme-chalk/display.css'
import { mapGetters } from 'vuex'
import pass from '@/assets/approval/pass.png'
import reject from '@/assets/approval/reject.png'
import revoked from '@/assets/approval/revoked.png'
import waiting from '@/assets/approval/waiting.png'

export default {
  name: 'permission',
  components: {
    PageHeaderLayout
  },
  computed: {
    ...mapGetters(['userInfo']),

  },
  data() {
    return {
      menuData: [
        { 'title': '收件箱', icon: 'inbox', unread: '' }
      ],
      pass: pass,
      reject: reject,
      revoked: revoked,
      waiting: waiting,
      stateTabs: 'waiting',
      applyList: [],
      activeNames: [],
      pagingData: {
        page_size: 20,
        current_page: 1,
        total: 0
      },
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      timeData: ''
      ,
      searchCondition: {
        handle_status: 'N',
        date_range: null
      },
      noticeLoading: false
    }
  },
  methods: {
    defaultDates() {
      let today = this.getLastDays(0)
      let last30Day = this.getLastDays(30)
      return [last30Day, today]
    },
    getLastDays(days) {
      //几天前的时间
      let day1 = new Date()
      day1.setTime(day1.getTime() - 24 * 60 * 60 * 1000 * days)
      return day1.getFullYear() + '-' + (day1.getMonth() + 1) + '-' + day1.getDate()
    },
    computedImg(status) {
      let data = this.waiting
      switch (status) {
        case 1 :
          data = this.waiting
          break
        case 2:
          data = this.pass
          break
        case 3:
          data = this.reject
          break
        case -1:
          data = this.revoked
          break
      }
      return data
    },

    async getFoYouApply() {
      this.noticeLoading = true
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, this.pagingData, { rec_user_id: this.userInfo.id })
      let { list, pages } = await this.$api.getMyRoleApplyRecords(searchCondition)
      this.applyList = list
      this.pagingData = pages
      this.noticeLoading = false
    },

    async handlePass(row) {
      //同意申请
      await this.$api.handlePassRoleApply({ id: row.id })
      await this.getFoYouApply()
    },
    async handleRej(row) {
      //拒绝申请
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      }).then(async ({ value }) => {
        await this.$api.handleRejRoleApply({ id: row.id, rej_reason: value })
        this.pagingData.current_page = 1
        await this.getFoYouApply()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消操作'
        })
      })
    },
    load() {
        this.pagingData.current_page = this.pagingData.current_page + 1
        this.getFoYouApply()

    },
    handleTabs() {
      this.pagingData.current_page = 1
      if (this.stateTabs !== 'all') {
        this.searchCondition.handle_status = this.stateTabs === 'waiting' ? 'N' : 'Y'
      } else if (this.stateTabs === 'all') {
        this.searchCondition.handle_status = null
      }
      this.getFoYouApply()
    },
    handleTimePicker(){
      this.pagingData.current_page = 1
      this.getFoYouApply()

    },
    handleSearchCondition() {
      let cond = {}
      if (this.searchCondition.handle_status) {
        cond['handle_status'] = this.searchCondition.handle_status
      }
      if (this.searchCondition.date_range) {
        cond['date_range'] = this.searchCondition.date_range
      }
      return cond
    }
  },
  mounted() {
    this.searchCondition.date_range = this.defaultDates()

    this.getFoYouApply()
  }
}
</script>

<style scoped lang="scss">

.menuBox {
  .menu {
    height: 38px;
    width: 100%;
    line-height: 38px;
    border-radius: 10px;
    cursor: pointer;
    text-align: left;
    color: rgb(99, 108, 118);

    > div {
      margin-left: 10px;
      font-size: 14px;

      > span {
        display: inline-block;
        float: right;
        margin-right: 30px;
      }
    }
  }

  .active {
    background: #ff3176;
    color:#FFFFFF;
  }
}

.noticeList {
  margin-bottom: 20px;
}

.noticeBox {
  height: 700px;
  overflow: scroll;

  //.noticeItem {
  //  height: 80px;
  //  padding-top: 10px;
  //}
}

::v-deep .crTime {
  margin-left: 10px;
}


.contentBox {
  width: 100%;
  float: left;
  display: flex;
  padding: 10px 0 20px 0;
  //justify-content: center;

  .statusImg {
    width: 60px;
  }

  .text {
    height: 60px;
    width: calc(100% - 180px);
    margin-left: 20px;

  }


}
</style>
